<template lang="pug">
  div
    v-toolbar(
      :color="editMode ? 'yellow darken-3' : 'green'"
      flat
      dense
      dark
      rounded
    )
      span {{ editMode ? 'Update Details' : 'Add Details' }}
    v-row
      v-col(cols="3")
        create-voucher-details(
          :default-data.sync="editVoucherDetail"
        )
      v-col(cols="9")
        table-voucher-details(
          :edit.sync="editVoucherDetail"
        )
</template>
<script>
export default {
  name: 'VoucherDetails',
  components: {
    createVoucherDetails: () => import('./Create'),
    tableVoucherDetails: () => import('./Table'),
  },
  data () {
    return {
      editVoucherDetail: {},
    }
  },
  computed: {
    editMode () {
      return !this.$objectEmpty(this.editVoucherDetail)
    },
  },
}
</script>